import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK, useLogout } from 'react-admin';
import permissionManager from './permissionManager';
import * as Sentry from "@sentry/browser";
import { sentryErrorHandler } from './SentryErrorHandler';

// Store permissions in memory
let cachedPermissions = null;

const fetchPermissions = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
        return Promise.reject("Unauthorized: Token not found");
    }
    const API_URL = process.env.REACT_APP_API_URL;
    const requestUrl = `${API_URL}/internal/administrators/me/permissions`;
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    };

    try {
        const response = await fetch(requestUrl, requestOptions);

        // Check if the response is a 403 or 401
        if (response.status === 403 || response.status === 401) {
            cachedPermissions = null;
            permissionManager.deletePerms();
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/#/login';
            return Promise.reject();
        }

        const user_permissions = await response.json();
        if (!user_permissions) {
            sentryErrorHandler('Request Failed: Could not fetch user permissions', 'fetchPermissions')
            cachedPermissions = null;
            permissionManager.deletePerms();
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/#/login';
            return Promise.reject("Invalid permissions response");
        }
        // Handle new granular permissions format
        let granular_permissions = new Map(Object.entries(user_permissions));
        if(user_permissions.email){
            localStorage.setItem('user_email', user_permissions.email);
            Sentry.setUser({ email: user_permissions.email})
        }
        if (granular_permissions.size > 10) {
            // Store granular permissions
            const granular_permissions_obj = Object.fromEntries(granular_permissions);
            localStorage.setItem('granular_permissions', JSON.stringify(granular_permissions_obj));
            cachedPermissions = new Map(Object.entries(user_permissions)); 
        }
        // Handle old permissions format (fallback)
        else handlePermissionsFallback(user_permissions)
        return Promise.resolve(cachedPermissions);
    } catch (error) {
        sentryErrorHandler(error, 'fetchPermissions')
        return Promise.reject();
    }
}

const handlePermissionsFallback = (user_permissions) => {
    const platform_permissions = user_permissions.read_only && !user_permissions.super_admin ? 'read_only' : (user_permissions.super_admin ? 'full' : null);
    const billing_permissions = user_permissions.billing_admin ? 'full' : null;
    const permissions_management = user_permissions.permissions_admin ? 'full' : null;
    
    permissionManager.setPerms(platform_permissions);
    permissionManager.setBillingPerms(billing_permissions);
    permissionManager.setPermissionsPerms(permissions_management);
    cachedPermissions = permissionManager.getPerms();
};

export default async (type, params) => {
    switch (type) {
        case AUTH_LOGIN:
            localStorage.setItem('token', params.access_token);
            return await fetchPermissions();
        case AUTH_LOGOUT:
            cachedPermissions = null;
            permissionManager.deletePerms();
            sessionStorage.clear();
            localStorage.clear();
            return Promise.resolve();
        case AUTH_ERROR:
            if ([403, 401].includes(params.status)) {
                cachedPermissions = null;
                permissionManager.deletePerms();
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/#/login';
                return Promise.reject();
            }
            return Promise.resolve(params.message);
        case AUTH_GET_PERMISSIONS:
            if (cachedPermissions) return Promise.resolve(cachedPermissions);
            return fetchPermissions();
        case AUTH_CHECK:
            return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
        default:
            return Promise.reject(`Unknown method: ${type}`);
    }
};
