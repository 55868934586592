import logo from '../resources/img/IA.svg';
import { CircularProgress } from '@mui/material';

export const IALoading = (props) => {
    let sizeValue, sizeUnit;
    /*****************************************************************************\
    *   This checks if prop.size contains a stringified unit size (e.g. '5em').   * 
    *       If so, the component will scale and adjust to the desired size.       *
    *          If props.size is a number, it will default to pixels unit.         *
    \*****************************************************************************/
    if (typeof props.size === 'string') {
        const match = props.size.match(/^(\d*\.?\d+)([a-zA-Z%]*)$/);
        if (match) {
            sizeValue = parseFloat(match[1]);
            sizeUnit = match[2] || 'px';
        } else {
            sizeValue = 40;
            sizeUnit = 'px';
        }
    } else if (typeof props.size === 'number' && !isNaN(props.size)) {
        sizeValue = props.size;
        sizeUnit = 'px';
    } else {
        sizeValue = 40;
        sizeUnit = 'px';
    }
    const sizeWithUnit = `${sizeValue}${sizeUnit}`;
    const scaledSizeWithUnit = `${sizeValue * 1.1}${sizeUnit}`;
    return (
        <span style={{ position: "relative", display: "inline-block", width: sizeWithUnit, height: sizeWithUnit }}>            <img
            src={logo}
            alt="Internal Admin Loading Logo"
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: scaledSizeWithUnit,
                height: scaledSizeWithUnit,
            }}
        />
            <CircularProgress
                variant="indeterminate"
                size={sizeWithUnit}
                thickness={2}
                sx={{
                    color: "white",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
            />
        </span>
    );
};