import React, { useCallback, useEffect, useState } from "react";
import {
    TextField, Table, TableBody,
    TableCell, TableContainer, TableRow, Paper, Typography,
    TableHead, IconButton, Tooltip, Select, MenuItem, FormControl,
    TablePagination, Checkbox, FormControlLabel, Card, Backdrop, Fade, Slide,
    Alert,
    AlertTitle
} from '@mui/material'
import { useDataProvider, useNotify, Button, Title, Form } from "react-admin";
import { ChevronRight, EditRoad, ExpandMore, GetApp, Map, RemoveCircleOutline } from "@mui/icons-material";
import { TripRouteDownload } from "./TripRouteDownloadButton";
import { useNavigate } from "react-router-dom";
import img from '../resources/img/TripRoutes.png'
import { IALoading } from "./IAProgress";
import { styled } from "@mui/system";

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
}));
const FailedImportCell = styled(TableCell)(({ theme }) => ({
    textAlign: 'center',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
}));

const MAX_TRACE_PTS = 50000
const MAX_RESULTS = 100

const RenderTripRoute = ({ routes, username, clientKey }) => {
    const [loadingRouteId, setLoadingRouteId] = useState(null);
    const [loadingSnapshotId, setLoadingSnapshotId] = useState(null);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [gotGeometry, setGotGeometry] = useState(null);
    const [geometry, setGeometry] = useState([]);
    const [badChunks, setBadChunks] = useState(null);
    const [snapShotData, setSnapShotData] = useState(null);
    const [polylines, setPolylines] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [reasonCodes, setReasonCodes] = useState({});
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();

    const getSnapshotData = async (loadId, uuid) => {
        setLoadingMessage("Retrieving snapshot data.")
        dataProvider.getOne('trip_plans', {
            filter: { clientKey: clientKey, username: username, loadId: loadId, uuid: uuid }
        }).then(({ data }) => {
            setSnapShotData(data);
            setLoadingSnapshotId(null);
            navigate('/here_map', { state: { geometry: null, polylines: null, badChunks: null, snapShotData: data } })
        }).catch(error => {
            notify(`Error retrieving snapshot: ${error}`, { type: 'error' })
        })
    }

    const getReasonCodeData = async (loadId, uuid) => {
        // Only fetch if not already loaded
        if (!reasonCodes[uuid]) {
            dataProvider.getList('trip_plans', { filter: { clientKey, username, loadId } })
                .then(({ data }) => {
                    const item = data.find(item => item.uuid === uuid);
                    const codes = item ? item.reason_codes.join(", ") : "No matching reason codes found.";
                    setReasonCodes(prev => ({ ...prev, [uuid]: codes }));
                }).catch(err => {
                    notify(`Error retrieving reason codes: ${err.message}`, { type: 'error' });
                    setReasonCodes(prev => ({ ...prev, [uuid]: "Error loading reason codes" }));
                })
        }
    };

    const fetchGeometry = async (routeId) => {
        setGotGeometry(null);
        setLoadingRouteId(routeId);
        setLoadingMessage(`Retrieving route ${routeId} geometry.`)
        try {
            const { data, error } = await dataProvider.getOne('routes', {
                id: routeId,
            });
            if (error) throw new Error(error)
            const tracePoints = data.geometry.map(point => ({
                lat: point.latitude,
                lng: point.longitude,
            }));
            setGeometry(tracePoints);
            setGotGeometry(true);
            if (tracePoints.length > MAX_TRACE_PTS) {
                setLoadingMessage(`Too many trace points (${tracePoints.length}). Getting bad chunks only...`)
                tracePoints.length > 0
                    ? navigate('/here_map', { state: { geometry: tracePoints, polylines, badChunks: data.bad_geometry_chunks, snapShotData } })
                    : notify('Unable to debug route: Geometry length is 0', { type: 'error' })
            } else {
                setLoadingMessage('Retrieving HERE route import response.')
                try {
                    const { data: hereData, error } = await dataProvider.update('here_call', { id: routeId, data: tracePoints });
                    if (hereData) {
                        notify('Received HERE import response.', { type: 'success' });
                        const lineData = hereData.routes.flatMap(route =>
                            route.sections.map(section => section.polyline)
                        );
                        tracePoints.length > 0
                            ? navigate('/here_map', { state: { geometry: tracePoints, polylines: lineData, badChunks, snapShotData, routeId } })
                            : notify('Unable to debug route: Geometry length is 0', { type: 'error' })
                    } else throw new Error(error);
                } catch (error) {
                    notify(`Error importing route: ${error}`, { type: 'error' });
                }
            }
        } catch (err) {
            notify(`Error retrieving geometry: ${err}`, { type: 'error' });
            setGotGeometry(false);
        } finally {
            setLoadingRouteId(null);
        }
    };

    const handleTPSClick = async (routeId, loadId, uuid) => {
        setLoadingSnapshotId(routeId);
        getSnapshotData(loadId, uuid)
    }

    const handleExpand = (id, loadId, uuid) => {
        setExpandedRow(expandedRow === id ? null : id);
        if (expandedRow !== id) getReasonCodeData(loadId, uuid)
    }

    const RenderReasonCodes = ({ uuid, reasonCodes }) => {
        return (
            <TableRow sx={{ backgroundColor: '#f9f9f9' }}>
                <TableCell />
                <TableCell colSpan={9}>
                    <Typography component="div" variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center', gap: '4px' }}><b>Snapshot Reason Code(s):</b> {reasonCodes[uuid] || <IALoading size={20} />}</Typography>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <React.Fragment>
            {routes.map((trip_route) => (
                <TableBody key={`body-${trip_route.id}`}>
                    <TableRow key={trip_route.id} hover>
                        <TableCell>
                            <Tooltip title="Show Reason Codes">
                                <IconButton onClick={() => handleExpand(trip_route.id, trip_route.load_reference, trip_route.trip_plan_uuid)}>
                                    {expandedRow === trip_route.id ? <ExpandMore /> : <ChevronRight />}
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>{trip_route.id}</TableCell>
                        <TableCell>{trip_route.trip_plan_uuid}</TableCell>
                        <TableCell>{trip_route.routing_engine || 'HERE Standard'}</TableCell>
                        <TableCell>{trip_route.load_reference}</TableCell>
                        {trip_route.import_status === "failed"
                            ? (<FailedImportCell>FAILED</FailedImportCell>)
                            : (<TableCell style={{ textAlign: "center" }}>{trip_route.import_status || "-"}</TableCell>)
                        }
                        <TableCell>{trip_route.created_at}</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            <FormControl size="small">
                                <Select id="download-select" disabled={!trip_route.trip_plan_uuid} displayEmpty renderValue={() => <GetApp color="primary" fontSize="small" />}>
                                    <MenuItem disabled value="">Select Download:</MenuItem>
                                    {['GPX', 'Load', 'Snapshot'].map(type => (
                                        <MenuItem key={`${trip_route.id}-${type}`}>
                                            <TripRouteDownload
                                                label={type}
                                                trip_plan_uuid={trip_route.trip_plan_uuid}
                                                loadId={trip_route.load_reference}
                                                fileType={type.toLowerCase()}
                                                username={username}
                                                clientKey={clientKey}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            {loadingRouteId === trip_route.id
                                ? (<Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, backdropFilter: 'blur(10px)' })} open={loadingRouteId === trip_route.id}>
                                    <Fade in>
                                        <div style={{ textAlign: 'center' }}>
                                            <Typography variant="h6">Please wait. You will be redirected soon.</Typography>
                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1em' }}>
                                                <IALoading />
                                                <Slide direction="right" in mountOnEnter unmountOnExit key={loadingMessage}>
                                                    <Typography variant="body1" style={{ padding: 10 }}>{loadingMessage}</Typography>
                                                </Slide>
                                            </div>
                                        </div>
                                    </Fade>
                                </Backdrop>)
                                : (<Tooltip title="Pluggable Debugger">
                                    <span>
                                        <IconButton disabled={!(trip_route.import_status === 'failed' && !gotGeometry)} onClick={() => fetchGeometry(trip_route.id)} color="primary">
                                            <EditRoad />
                                        </IconButton>
                                    </span>
                                </Tooltip>)
                            }
                        </TableCell>
                        <TableCell>
                            <div style={{ display: 'flex' }}>
                                {loadingSnapshotId === trip_route.id
                                    ? (<Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={loadingSnapshotId === trip_route.id}>
                                        <Fade in>
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="h6">Please wait. You will be redirected soon.</Typography>
                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1em' }}>
                                                    <IALoading />
                                                    <Slide direction="right" in mountOnEnter unmountOnExit key={loadingMessage}>
                                                        <Typography variant="body1" style={{ padding: 10 }}>{loadingMessage}</Typography>
                                                    </Slide>
                                                </div>
                                            </div>
                                        </Fade>
                                    </Backdrop>)
                                    : (<IconButton disabled={!trip_route.trip_plan_uuid} color="primary" onClick={() => { handleTPSClick(trip_route.id, trip_route.load_reference, trip_route.trip_plan_uuid) }}><Map /></IconButton>)
                                }
                            </div>
                        </TableCell>
                    </TableRow>
                    {expandedRow === trip_route.id && <RenderReasonCodes uuid={trip_route.trip_plan_uuid} reasonCodes={reasonCodes} />}
                </TableBody>
            ))}
        </React.Fragment>
    );
};

export const TripRoutesForm = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showPagination, setShowPagination] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [username, setUsername] = useState(localStorage.getItem("TR_username") || "");
    const [clientKey, setClientKey] = useState(localStorage.getItem("TR_client_key") || "");
    const [start, setStart] = useState(localStorage.getItem("TR_start") || "");
    const [end, setEnd] = useState(localStorage.getItem("TR_end") || "");
    const [showLoadIDField, setShowLoadIDField] = useState(false);
    const [loadIdFilter, setLoadIdFilter] = useState("");
    const [filteredRoutes, setFilteredRoutes] = useState(props.routes || []);
    const [routes, setRoutes] = useState([]);
    const [requestSent, setRequestSent] = useState(false)
    const [loading, setLoading] = useState(false);
    const [filtering, setFiltering] = React.useState(false);
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const calculateDefaultDates = useCallback(() => {
        const now = new Date();
        const twoWeeksAgo = new Date(now);
        twoWeeksAgo.setDate(now.getDate() - 14);
        return {
            start: twoWeeksAgo.toISOString(),
            end: now.toISOString(),
        };
    }, []);

    useEffect(() => {
        if (!start || !end) {
            const { start: defaultStart, end: defaultEnd } = calculateDefaultDates();
            setStart(defaultStart);
            setEnd(defaultEnd);
        }
        if (username && clientKey && start && end) requestTripRoutes();
    }, []);

    useEffect(() => {
        if (showLoadIDField && loadIdFilter) {
            setFiltering(true);
            setTimeout(() => {
                setFilteredRoutes(
                    routes.filter((route) =>
                        route.load_reference.toString().includes(loadIdFilter)
                    )
                );
                setFiltering(false);
            }, 500);
        } else if (showLoadIDField) {
            setFilteredRoutes(routes);
        }
    }, [loadIdFilter, showLoadIDField, routes]);

    const handleClear = () => {
        ["TR_client_key", "TR_username", "TR_start", "TR_end"].forEach((key) => localStorage.removeItem(key));
        setRequestSent(false);
        setClientKey("");
        setUsername("");
        setRoutes([]);
        setFilteredRoutes([]);
        setLoadIdFilter("");
        setShowLoadIDField(false);
        setShowCheckbox(false);
        const { start: defaultStart, end: defaultEnd } = calculateDefaultDates();
        setStart(defaultStart);
        setEnd(defaultEnd);
    };

    const requestTripRoutes = async () => {
        setLoading(true);
        dataProvider.getList('trip_routes', { filter: { clientKey, username, start, end } })
            .then(({ data }) => {
                setRequestSent(true);
                setRoutes(data);
                setFilteredRoutes(data);
                setShowCheckbox(true);
                setLoading(false);
            })
            .catch(error => {
                notify(`Error fetching trip routes: ${error}`, { type: 'error' });
                setLoading(false);
            })
    }

    const submitHandler = () => {
        setLoading(true);

        const fields = { clientKey, username, start, end };
        const labels = {
            clientKey: "Client Key",
            username: "Username",
            start: "Start Date",
            end: "End Date",
        };
        for (const [key, value] of Object.entries(fields)) {
            if (!value) {
                const keyLabel = labels[key] || key;
                notify(`${keyLabel} is required`, { type: "error" });
                setLoading(false);
                return;
            }
        }
        Object.entries(fields).forEach(([key, value]) => localStorage.setItem(`TR_${key}`, value));
        setLoadIdFilter("");
        setShowLoadIDField(false);
        setFilteredRoutes(routes);
        requestTripRoutes();
    };

    const handleChangePage = (_, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const RenderTripRoutes = React.memo(({ displayedRoutes }) => {
        return (
            <TableContainer component={Paper} elevation={3} style={{ maxHeight: "80vh" }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow style={{ padding: 1 }}>
                            <StyledTableHeadCell></StyledTableHeadCell>
                            <StyledTableHeadCell><b>Route ID</b></StyledTableHeadCell>
                            <StyledTableHeadCell><b>UUID</b></StyledTableHeadCell>
                            <StyledTableHeadCell><b>Routing Engine</b></StyledTableHeadCell>
                            <StyledTableHeadCell><b>Load ID</b></StyledTableHeadCell>
                            <StyledTableHeadCell><b>Import Status</b></StyledTableHeadCell>
                            <StyledTableHeadCell><b>Created At</b></StyledTableHeadCell>
                            <StyledTableHeadCell style={{ textAlign: 'center' }}><b>Downloads</b></StyledTableHeadCell>
                            <StyledTableHeadCell style={{ textAlign: 'center' }}><b>PR Debug</b></StyledTableHeadCell>
                            <StyledTableHeadCell style={{ textAlign: 'center' }} ><b>NTPS</b></StyledTableHeadCell>
                        </TableRow>
                    </TableHead>
                    <RenderTripRoute routes={displayedRoutes} username={username} clientKey={clientKey} />
                </Table>
                {showPagination && (
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={routes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </TableContainer>
        )
    });

    const RenderResults = () => {
        if (loading) return <div style={{ marginTop: "3em", justifySelf: 'center' }}><IALoading size={'5em'} /></div>;
        if (requestSent) {
            let displayedRoutes = filteredRoutes ? (showLoadIDField ? filteredRoutes : routes) : routes;
            return (
                <div>
                    {routes.length === MAX_RESULTS && (
                        <Alert severity="warning" elevation={3} sx={{marginBottom:'2em'}}>
                            <AlertTitle><strong>Maximum results ({MAX_RESULTS}) reached.</strong></AlertTitle>
                            <Typography variant="body1">
                                There may be more trip routes available—try narrowing your search by adjusting the date range.
                            </Typography>
                        </Alert>
                    )}
                    {displayedRoutes.length > 0 
                        ? <RenderTripRoutes displayedRoutes={showPagination ? displayedRoutes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : displayedRoutes} />
                        : <Typography>No Trip Routes Found</Typography>
                    }
                </div>
            );
        }
        return null;
    };

    const handleShowLoadIdCheckbox = (e) => {
        setShowLoadIDField(e.target.checked)
        if (e.target.checked) {
            setLoadIdFilter("");
            setFilteredRoutes(routes);
        }
    }
    const clearFilter = () => {
        setLoadIdFilter("");
        setShowLoadIDField(false);
        setFilteredRoutes(routes);
    }

    return (
        <div style={{ padding: '3rem' }}>
            <Title title="Trip Routes" />
            <div style={{ display: 'flex', flexDirection: 'row', justifySelf: 'center', gap: '3rem', alignItems: 'flex-start' }}>
                <Card style={{ padding: '2.4rem', maxWidth: '25rem', maxHeight: '25rem', boxShadow: '0 4px 8px #2c2b2b', borderRadius: '8px' }}>
                    <Form onSubmit={submitHandler} style={{ display: 'content' }}>
                        <div style={{ marginBottom: '1em' }}>
                            <TextField
                                variant="outlined"
                                label="Client Key*"
                                value={clientKey}
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                    setClientKey(e.target.value);
                                    localStorage.setItem("TR_client_key", e.target.value);
                                }}
                            />
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <TextField
                                variant="outlined"
                                label="Username*"
                                value={username}
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                    localStorage.setItem("TR_username", e.target.value);
                                }}
                            />
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <TextField
                                variant="outlined"
                                label="Start Date (UTC)"
                                type="datetime-local"
                                value={start.slice(0, -1)}
                                fullWidth
                                onChange={(e) => setStart(e.target.value + "Z")}
                            />
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <TextField
                                variant="outlined"
                                label="End Date (UTC)"
                                type="datetime-local"
                                value={end.slice(0, -1)}
                                fullWidth
                                onChange={(e) => setEnd(e.target.value + "Z")}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '1em' }}>
                            <Button type="submit" variant="contained" color="primary" label="Request" />
                            <Button onClick={handleClear} variant="outlined" style={{ backgroundColor: '#c8c8c8' }} label="Clear" />
                        </div>
                    </Form>
                </Card>
                <div style={{ display: 'flex', minWidth: '50%', textAlign: 'center' }}>
                    <img
                        src={img}
                        alt="TripRoutesLogo"
                        style={{
                            maxWidth: '25rem',
                            maxHeight: '25rem',
                            objectFit: 'contain',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px #2c2b2b'
                        }}
                    />
                </div>
            </div>
            {showCheckbox && (
                <div style={{ textAlign: 'center', margin: '1em' }}>
                    <FormControlLabel
                        control={<Checkbox size="small" checked={showPagination} onChange={(e) => setShowPagination(e.target.checked)} />}
                        label={<span style={{ fontSize: '0.85rem' }}>Paginate Results</span>}
                    />
                    {!showLoadIDField && (
                        <FormControlLabel
                            control={<Checkbox size="small" checked={showLoadIDField} onChange={(e) => handleShowLoadIdCheckbox(e)} />}
                            label={<span style={{ fontSize: '0.85rem' }}>Filter by Load ID</span>}
                        />
                    )}
                </div>
            )}
            <div style={{ marginTop: '2rem' }}>
                {showLoadIDField && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                            label="Filter by Load ID"
                            value={loadIdFilter}
                            onChange={(event) => setLoadIdFilter(event.target.value)}
                            variant="outlined"
                            size="small"
                            style={{ width: '30%', marginLeft: '1em', marginTop: '1em' }}
                        />
                        <IconButton onClick={clearFilter} style={{ marginTop: '0.3em' }}><RemoveCircleOutline /></IconButton>
                    </div>
                )}
                <RenderResults />
            </div>
        </div>
    );
}